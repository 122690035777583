import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import translations from "../translations"

import officeImage from "../images/office.jpg"

const ImprintPage = ({ pathContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={translations[locale].imprintPage.header} />
    <div className="page">
      <div className="container center">
        <h2 className="page-title">{translations[locale].imprintPage.header}</h2>
      </div>
      <div className="container row">
        <p className="half center">
          Oulong Technology GmbH<br />
          Karlsruher-Str. 3<br />
          D-70771 Leinfelden-Echterdingen<br />
          <br />
          {translations[locale].imprintPage.email}: contact@oulongtech.com<br />
        {translations[locale].imprintPage.phone}: +49 711 7828150<br />
        </p>
        <div className="half">
          <img src={officeImage} alt="Our office" />
        </div>
      </div>
    </div>
  </Layout>
)

export default ImprintPage
